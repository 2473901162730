import axios from 'axios'
import store from '@/store'
import router from '@/router' // Assurez-vous d'importer votre router pour la redirection

let testApiCode = store.getters['app/userToken']
let baseUrlLocal = store.getters['app/baseApiUrlLocal']
// let baseUrlLocal = `http://127.0.0.1:8000/sgs`
let baseUrl = store.getters['app/baseApiUrl']
const axiosIns = axios.create({
    baseURL: baseUrl,
    // baseURL: baseUrlLocal,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${testApiCode}`,
        'AuthorizationJwt': `${testApiCode}`
    }
})
// Par défaut pour toutes les requêtes
axiosIns.defaults.headers.common['Accept'] = 'application/json';
axiosIns.interceptors.response.use(
    response => {
        // Si la réponse est correcte, la retourne simplement
        return response;
    },
    error => {
        // Si le statut de la réponse est 401 (Unauthorized)
        if (error.response && error.response.status === 401) {
            // Optionnel : Vous pouvez également effacer le token de l'utilisateur ici
            store.commit('app/setUser',{});

            // Redirige l'utilisateur vers la page de connexion
            router.push('/login');
        }

        // Retourne l'erreur pour que les appels suivants puissent la gérer
        return Promise.reject(error);
    }
);

console.log('voici le store', testApiCode, baseUrlLocal)
export default axiosIns;
